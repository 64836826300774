<template>
  <div
    class="min-h-screen bg-white p-4 py-9 text-center mx-auto"
    style="max-width:425px; "
  >
    <div class="h-1/2  p-4">
      <div class="cool-font text-3xl mb-8">RSVP</div>
      <div class="mb-4">
        <button
          type="button"
          @click="download"
          class="flex w-64 focus:outline-none text-gray-500 uppercase font-bold border border-gray-500 rounded-full mx-auto p-3"
        >
          <div
            class="flex flex-row items-center justify-center sm:flex-cols-12 gap-2"
          >
            <div class="col-span-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                />
              </svg>
            </div>
            <div class="col-span-2">Download RSVP</div>
          </div>
        </button>
      </div>
      <div
        class="block w-full overflow-auto border-2"
        style="height: calc(100vh - 258px)"
      >
        <table
          class="items-center bg-transparent w-full border-collapse"
          border="1"
          frame="void"
          rules="rows"
        >
          <thead>
            <tr>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                No.
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Nama
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Status Kehadiran
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
              >
                Ucapan
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(val, i) in wishes"
              :key="i"
              :class="i % 2 == 0 ? 'bg-gray-200' : ''"
            >
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 "
              >
                {{ i + 1 }}
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 "
              >
                {{ val.nama }}
              </td>
              <td
                class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ val.kehadiran }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 whitespace-nowrap"
              >
                {{ val.ucapan }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import XLSX from "xlsx";
export default {
  setup() {
    const $http = inject("$http");

    let wishes = ref([]);

    let getWishes = async () => {
      let resp = await $http.get(`/rsvp/all`);
      wishes.value = resp.data;
    };

    let download = () => {
      let temp = JSON.parse(JSON.stringify(wishes.value));
      for (let item of temp) {
        delete item.ava;
      }
      var ws = XLSX.utils.json_to_sheet(temp);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "RSVP");
      XLSX.writeFile(wb, `DAFTAR RSVP.xlsx`);
    };

    onMounted(async () => {
      await getWishes();
    });

    return {
      wishes,
      download,
    };
  },
};
</script>
